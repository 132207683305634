@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.signin > form > h1 {
  color: black;
  font-size: 35px;
  margin-left: 100px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.signin > form {
  flex-direction: column;
  display: grid;
}

.signin {
  max-width: 300px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
}
.loginField {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 30px;
  border: 2px solid black;
  border-radius: 10px;
}
.passwordField {
  margin-bottom: 20px;
  height: 30px;
  border: 2px solid black;
  border-radius: 10px;
}

h6 {
  margin-top: 20px;
  margin-left: 60px;
}
